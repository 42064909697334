import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
// import Data from "../../Data";
import CrdaData from "../../CrdaData";
import { IoHome } from "react-icons/io5";
import ShopGridV1 from "../shop-components/shop-right-sidebar";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";

const BannerV6 = () => {

  const [publicUrl, setPublicUrl] = useState(process.env.PUBLIC_URL + "/");

  const [propType, setPropType] = useState("")

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSubLocation, setSelectedSubLocation] = useState('');

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };


  const handleSubLocationChange = (event) => {
    setSelectedSubLocation(event.target.value);
  };

  // const filteredAreas = selectedCity ? Data.city.find(city => city.name === selectedCity)?.areas || [] : [];

// ********************* Data Mapping for Locations, Sub Locations ***************************

const [Data, setData] = useState([]);

const GetLocationsData = async () => {
  try {
    const response = await Instence.get("Location");
    if (response.status === 200) {
      setData(response.data);
      console.log("Fetched Locations Data", response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  GetLocationsData();
}, []);

console.log("Filtered Data", Data);

const filteredAreas = selectedCity
  ? Data.find((item) => item.id === parseInt(selectedCity))?.areas.sort((a, b) => a.localeCompare(b)) || []
  : [];

console.log("Filtered Areas:", filteredAreas);


// ***************************************************************************


  const history = useHistory();

  const [listingType, setListingType] = useState("Sell")

  const handleTabClick = (type) => {
    setListingType(type);
    console.log(type)
  };


  const handleSearch = () => {
    // Save the search parameters to localStorage
    localStorage.setItem('searchParams', JSON.stringify({ propType, selectedCity, selectedSubLocation, listingType }));

    // Push to the shop page
    history.push('/shop');
  };


  return (
    <div className="ltn__slider-area ltn__slider-4 position-relative  ltn__primary-bg">
      <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
        <video autoPlay muted loop id="myVideo">
          <source src={publicUrl + "assets/media/3.mp4"} type="video/mp4" />
        </video>

        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30"
          data-bs-bg={publicUrl + "assets/img/slider/41.jpg"}
        >
          <div className="ltn__slide-item-inner text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="slide-item-car-dealer-form">
                    <div className="section-title-area ltn__section-title-2 text-center">
                      <h1 className="section-title  text-color-white">
                        Find Your{" "}
                        <span className="ltn__secondary-color-3">Perfect</span>{" "}
                        Properties
                      </h1>
                    </div>
                    <div className="ltn__car-dealer-form-tab">
                      <div className="ltn__tab-menu  text-uppercase text-center">
                        <div className="nav">
                          <a
                            className="active show"
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_1"
                            onClick={() => handleTabClick("Sell")}
                          >
                            {/* <i className="fa-solid fa-cart-shopping" /> */}
                            <i class="fas fa-home"></i>
                            Buy
                          </a>
                          <a
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_2"
                            className
                            onClick={() => handleTabClick("Rent")}
                          >
                            {/* <i className="fa-solid fa-truck-moving" /> */}
                            <i class="fas fa-hand-holding-usd" style={{ fontSize: "20px" }}></i>
                            Rent
                          </a>
                          <a
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_4"
                            className
                            onClick={() => handleTabClick("plot")}
                          >
                            <i class="far fa-building"></i>
                            CRDA/Plots
                          </a>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="ltn__form_tab_1_1"
                        >
                          <div className="car-dealer-form-inner">
                            <form
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                <select value={propType} onChange={(e) => setPropType(e.target.value)} className="nice-select">
                                  <option disabled value="">
                                    Property Type
                                  </option>
                                  <option value="Apartment">Apartment</option>
                                  <option value="Flat">Flat</option>
                                  <option value="Independent House">
                                    Independent House
                                  </option>
                                  <option value="Villa">Villa</option>
                                  <option value="Plot"> Open Plot</option>
                                  <option value="Agricultural Land">
                                    Agricultural Land
                                  </option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  onChange={handleCityChange}
                                  value={selectedCity}
                                >
                                  <option value="">Select City</option>
                                  {Data.map((city, index) => (
                                    <option key={index} value={city.id}>{city.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  value={selectedSubLocation}
                                  onChange={handleSubLocationChange}
                                >
                                  <option value="">Sub Location</option>
                                  {filteredAreas.map((area, index) => {
                                    console.log(area);
                                    return (
                                      <option key={index} value={area}>{area}</option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={handleSearch}>
                                    Search
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_2">
                          <div className="car-dealer-form-inner">
                            <form
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                <select value={propType} onChange={(e) => setPropType(e.target.value)} className="nice-select">
                                  <option disabled value="">
                                    Property Type
                                  </option>
                                  <option value="Apartment">Apartment</option>
                                  <option value="Flat">Flat</option>
                                  <option value="Independent House">
                                    Independent House
                                  </option>
                                  <option value="Villa">Villa</option>
                                  <option value="Plot">Plot</option>
                                  <option value="Agricultural Land">
                                    Agricultural Land
                                  </option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  onChange={handleCityChange}
                                  value={selectedCity}

                                >
                                  <option value="">Select City</option>
                                  {Data.map((city, index) => (
                                    <option key={index} value={city.id}>{city.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  value={selectedSubLocation}
                                  onChange={handleSubLocationChange}
                                >
                                  <option value="">Sub Location</option>
                                  {filteredAreas.map((area, index) => {
                                    console.log(area);
                                    return (
                                      <option key={index} value={area}>{area}</option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={handleSearch}>
                                    Search
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_4">
                          <div className="car-dealer-form-inner">
                            <form

                              className="ltn__car-dealer-form-box row"
                            >
                              <div value={propType} onChange={(e) => setPropType(e.target.value)} className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                <select className="nice-select">
                                  <option disabled value="">
                                    Property Type
                                  </option>
                                  <option value="Plot">Plot</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  onChange={handleCityChange}
                                  value={selectedCity}

                                >
                                  <option value="">Select City</option>
                                  {Data.map((city, index) => (
                                    <option key={index} value={city.id}>{city.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <select
                                  className="nice-select"
                                  value={selectedSubLocation}
                                  onChange={handleSubLocationChange}
                                >
                                  <option value="">Sub Location</option>
                                  {filteredAreas.map((area, index) => {
                                    console.log(area);
                                    return (
                                      <option key={index} value={area}>{area}</option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={handleSearch}>
                                    Search
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerV6;